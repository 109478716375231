@import '../../styles//customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.title {
  composes: marketplaceModalTitleStyles from global;
}

.paragraph {
  composes: marketplaceModalParagraphStyles from global;
  line-height: 24px;

  @media (--viewportMedium) {
    line-height: 26px;
  }
}

.selectReason {
  composes: marketplaceModalParagraphStyles from global;
  font-weight: bold;
}

.letUsKnowAlert {
  composes: successAlert from global;
}

.hidden {
  display: none;
}

.cpSelect {
  padding-left: 26px;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.section {
  padding: 0 24px;
  margin-top: 60px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.submitContainer {
  margin-top: 60px;
}

.activateError {
  color: var(--failColor);
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.closeError {
  color: var(--failColor);
  text-align: center;
}

.submitButtons {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.cancelButton {
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-left: 24px;
  }
}

.enquiredUsersError {
  margin-left: 2px;
  color: var(--failColor);
  font-size: 14px;
}

.otherListingsContainer {
  margin-top: 18px;
}

.other {
  padding-left: 26px;
}

.nameOfRecipientInput {
  margin-top: 16px;
}
